import React from 'react';
import { Link } from 'react-router-dom';
import './DealList.css'; // We'll create a CSS file for styling

const DealList = ({ deals, loading, error }) => {
  if (loading) {
    return <p>Loading deals...</p>;
  }

  if (error) {
    return <p>{error}</p>;
  }

  return (
    <div className="deal-list">
      {deals.map((deal) => (
        <div key={deal.id} className="deal-card">
          <p className="deal-text">{deal.text}</p>
          {deal.photoUrl && <img src={deal.photoUrl} alt="Deal" className="deal-image" />}
          <Link to={`/deal/${deal.id}`} className="deal-link">View Details</Link>
        </div>
      ))}
    </div>
  );
};

export default DealList;
