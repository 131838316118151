import React from 'react';
import { useParams } from 'react-router-dom';
import { Link } from 'react-router-dom';

const DealDetailsPage = ({ deals }) => {
  const { id } = useParams();
  const deal = deals.find((d) => d.id === parseInt(id, 10));

  if (!deal) {
    return <p>Deal not found.</p>;
  }

  return (
    <div className="deal-details">
      <h2>Deal Details</h2>
      <p>{deal.text}</p>
      {deal.photoUrl && <img src={deal.photoUrl} alt="Deal" />}
      <Link to="/" className="back-link">Back to Deals</Link>
    </div>
  );
};

export default DealDetailsPage;
