import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Header from './Header';
import DealList from './DealList';
import DealDetailsPage from './DealDetailsPage';
import AboutUs from './AboutUs';

function App() {
  const [deals, setDeals] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchDeals = async () => {
      try {
        const response = await axios.get('https://your-project-name.vercel.app/api/fetchDeals');
        setDeals(response.data);
      } catch (error) {
        console.error('Error fetching deals:', error);
        setError('Failed to fetch deals. Please try again later.');
      } finally {
        setLoading(false);
      }
    };

    fetchDeals();
  }, []);

  return (
    <Router>
  <div className="App">
  <Header />
        <Routes>
          <Route path="/" element={<DealList deals={deals} loading={loading} error={error} />} />
          <Route path="/about-us" element={<AboutUs />} />
          <Route path="/deal/:id" element={<DealDetailsPage deals={deals} />} />
        </Routes>
      </div>
      
    </Router>
  );
}

export default App;
