import React, { useEffect, useState, useRef } from 'react';
import './HomePage.css';
import { Link } from 'react-router-dom';
import PartnersSection from './PartnersSection'; // Import the PartnersSection component


const Header = () => {
  const iframeRef = useRef(null); // Create a reference for the iframe element

  const [stats, setStats] = useState({
    Users: 5000000,
    Deals: 5000000,
    Brands: 500,
    members: 200000,
  });

  useEffect(() => {
    const storedStats = JSON.parse(localStorage.getItem('stats')) || {
      Users: 5000000,
      Deals: 5000000,
      Brands: 500,
      members: 200000,
    };

    const newStats = {
      Users: storedStats.Users + Math.floor(Math.random() * 0 + 1),
      Deals: storedStats.Deals + Math.floor(Math.random() * 0 + 1),
      Brands: storedStats.Brands + Math.floor(Math.random() * 0 + 0),
      members: storedStats.members + Math.floor(Math.random() * 0 + 0),
    };

    setStats(newStats);
    localStorage.setItem('stats', JSON.stringify(newStats));
  }, []);

  const handleGrabDealsClick = () => {
    // Scroll to the iframe section when Grab Deals button is clicked
    iframeRef.current.scrollIntoView({ behavior: 'smooth' });
  };

  const deals = [
    {
      id: 1,
      brand: "AJIO",
      image: "https://asset22.ckassets.com/resources/image/staticpage_images/19-1721373521.jpg", // Placeholder for image path
      discount: "Min. 60% Off",
      description: "On Handbags",
      timeLeft: "04d:08h:24m:13s",
      startingFrom: "₹195",
      link: "https://ajiio.in/SdEscYd",
    },
    {
      id: 2,
      brand: "Lifespan",
      image: "https://asset22.ckassets.com/resources/image/staticpage_images/22-1721634154.jpg",
      discount: "₹79",
      description: "Rapid Relief Roll On",
      timeLeft: "02d:08h:24m:13s",
      startingFrom: "₹79",
      link: "https://ajiio.in/SdEscYd",
    },
    {
      "id": 3,
      "brand": "Mamaearth",
      "image": "https://asset22.ckassets.com/resources/image/staticpage_images/25-1721905381.jpg",
      "discount": "Buy 1 Get 1 Free",
      "description": "On Fragrances",
      "timeLeft": "00d:08h:11m:17s",
      "startingFrom": "₹599",
      "profit": "Upto 15% Profit",
      "link": "https://bitli.in/Oocf4bm",
    }
,
{
  "id": 4,
  "brand": "Bombay Shaving Company",
  "image": "https://asset22.ckassets.com/resources/image/staticpage_images/01-1722494883.jpg",
  "discount": "₹99",
  "description": "Buy Any Product @ ₹99 Only",
  "timeLeft": "02d:03h:11m:17s",
  "startingFrom": "₹99",
  "profit": "Flat 18% Profit",
  "link": "https://bitli.in/qeN1PuY",
}
,
{
  "id": 5,
  "brand": "Flipkart",
  "image": "https://asset22.ckassets.com/resources/image/staticpage_images/12-1720763168.jpg",
  "discount": "₹1199",
  "description": "Juicer Mixer Grinder",
  "timeLeft": "01d:08h:11m:17s",
  "startingFrom": "₹1,199",
  "profit": "Flat 2.4% Profit",
  "link": "https://fktr.in/Fg5tnNy",
}
,
    // Add more deal objects as needed
  ];
  

  
  return (
    <div className="landing-page">
      <div className="homepage">
        <h2 className="homepage-subtitle">Not getting right shopping deals?</h2>
        <h1 className="homepage-title">Get all price crashed deals at one place</h1>
        <p className="homepage-description">
          Why search across multiple sites when you can find all the deals in one place?
        </p>
        <button className="homepage-button" onClick={handleGrabDealsClick}>Grab Deals</button>
        <Link to="https://play.google.com/store/apps/details?id=com.offerrush.deals&hl=en_IN&gl=US" className="homepage-button">Download App</Link>
        <br />
      </div>

      <br /><br />
      <br /><br />
      <br /><br />
      <br /><br />

      <div className="stats-container">
        <div className="stat">
          <h2>{stats.Deals}+</h2>
          <p>Deals</p>
        </div>
        <div className="stat">
          <h2>{stats.Users}+</h2>
          <p>Users</p>
        </div>
        <div className="stat">
          <h2>{stats.Brands}+</h2>
          <p>Brands</p>
        </div>
        <div className="stat">
          <h2>{stats.members}+</h2>
          <p>Community members</p>
        </div>
      </div>

      <br /><br />
      <br /><br />
      <br /><br />
      <br /><br />

<div className="flash-deals">
  <h2>FLASH DEALS</h2>
  {deals.map((deal) => (
    <div key={deal.id} className="deal-card">
      <div className="deal-header">
        <img src={deal.image} alt={deal.brand} className="deal-image" />
      </div>
      <div className="deal-content">
        <div className="deal-brand">{deal.brand}</div>
        <div className="deal-discount">{deal.discount}</div>
        <div className="deal-description">{deal.description}</div>
        <div className="deal-timer">DEAL ENDS IN {deal.timeLeft}</div>
        <div className="deal-pricing">
          <div className="starting-from">STARTING FROM {deal.startingFrom}</div>
        </div>
      </div>
      <div className="deal-actions">
        {/* Ensure the button is clickable and redirects to the deal link */}
        <button
          className="copy-link"
          onClick={() => window.location.href = deal.link}
        >
          GRAB DEAL
        </button>
      </div>
    </div>
  ))}
  {/*<a href="#" className="view-all">View All</a>*/}
</div>
      <br /><br />


      <div className="video-deals">
  <h2>Drone Deals</h2>
  {[

{
  id: 6,
  videoUrl: "https://www.youtube.com/embed/qVLsA2l77w0",
  link: "https://amzn.to/3WzqlHm"

  
},


{
  id: 5,
  videoUrl: "https://www.youtube.com/embed/V3AbVnbDv1A",
  link: "https://amzn.to/4dfI123"

  
},


    {
      id: 1,
      videoUrl: "https://www.youtube.com/embed/HfYgBvaiWco",
      link: "https://amzn.to/4dHJcHr"

      
    },
    {
      id: 2,
      videoUrl: "https://www.youtube.com/embed/DPYuHH7pWs4",
      link: "https://amzn.to/3YBQ3Ol"

    },
    {
      id: 3,
      videoUrl: "https://www.youtube.com//embed/gBwiAuFSkZk",
      link: "https://amzn.to/46IfxLS"

    },
    {
      id: 4,
      videoUrl: "https://www.youtube.com/embed/8oC3SRa6AMc",
      link: "https://amzn.to/3Av4TvN"

    },

    
  ]
  
  .map((deal) => (
    <div key={deal.id} className="video-card" id={`card-${deal.id}`}>
      <div className="VIDEO">
        {/* Embedded YouTube video */}
        <iframe
          width="200%"
          height="315"
          src={deal.videoUrl}
          title={`YouTube video player ${deal.id}`}
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        ></iframe>
           <div className="deal-actions">
        {/* Clickable button redirecting to the deal link */}
        <button
          className="copy-link"
          onClick={() => window.location.href = deal.link}
        >
          GRAB DEAL
        </button>
         {/* Copy Link button */}
         <button
          className="copy-link"
          onClick={() => {
            const cardLink = `${window.location.origin}${window.location.pathname}#card-${deal.id}`;
            navigator.clipboard.writeText(cardLink);
            alert("Link copied to clipboard!");
          }}
        >
          COPY LINK
        </button>
      </div>
      </div>
    </div>
  ))}
</div>


















      <div className="how-it-helps">
        <h2 className="homepage-subtitle">Grab Limited Time Deals Before it Ends</h2>
        <p className="homepage-description">
          Please Allow Ads To See Amazing Deals From 500+ Brands
        </p>
      </div>
      
      <br /><br />

      <iframe
        ref={iframeRef} // Reference to the iframe element
        src="https://inrdeals.com/embed/deals?user=dev421118321"
        width="100%"
        height="1140"
        frameBorder="0"
        allowTransparency="true"
      ></iframe>

      <div className="how-it-helps">
        <h2>How Offerrush Helps You</h2>
        <div className="help-cards">
          <div className="help-card">
            <span>😎</span>
            <h3>Don't Pay us</h3>
            <p>
              Offerrush is Free. You don't need to pay Offerrush. Pay at your
              favourite merchant site like Myntra, Flipkart
            </p>
          </div>
          <div className="help-card">
            <span>📄</span>
            <h3>500+ Brands Offers</h3>
            <p>Tons of Offers on your favourite products</p>
          </div>
          <div className="help-card">
            <span>⏰</span>
            <h3>Limited Time Deals</h3>
            <p>
              Offerush provide limited time deals. They are special one be active
              to grab them
            </p>
          </div>
          <div className="help-card">
            <span>💰</span>
            <h3>One Site All Offers</h3>
            <p>We inform you about lowest price deals available in the market</p>
          </div>
        </div>
        <br /><br />
        <button className="homepage-button" onClick={handleGrabDealsClick}>Get started</button>
      </div>

      <h2>Great offers from 500+ leading brands</h2>
      <p>Save your hard earned money</p>
      <PartnersSection />

      <br /><br />

      {/* Banner Section */}
      <div className="banner-section">
        <a href="https://t.me/itechsearchtg" target="_blank" rel="noopener noreferrer">
          <img
            src="https://pricehistoryapp.com/_next/image?url=https%3A%2F%2Fcdn.pricehistory.in%2Fmedia%2Fbanners%2FSecret_deals_only.png&w=1080&q=75"
            alt="Secret Deals Banner"
            className="banner-image"
          />
        </a>
      </div>

      <br /><br />

      <footer className="footer">
        <div className="footer-container">
          <div className="footer-logo">
            <img
              src="https://play-lh.googleusercontent.com/OX4f94MmstgeUAm2LW2tHT34WUhqLNaQIxxU-2MbMrUyJIx06jTe_Km-68rEOtBmeA"
              alt=""
            />
            <p>MADE WITH ❤️ FOR THE GREAT MINDS</p>
            <p>
              Offerrushdeals.com is a participant in the Amazon Services LLC
              Associates Program, an affiliate advertising program designed to
              provide a means for sites to earn advertising fees by advertising
              and linking to amazon.in
            </p>
            <p>Contact Us: contact@offerrushdeals.com</p>
          </div>
          <div className="footer-links">
            {/* Footer links */}
            <div>
              <h3>Company</h3>
              <Link to="https://www.offerrushdeals.com/disclaimer">Disclaimer</Link>
              <Link to="https://www.offerrushdeals.com/about-us">About Us</Link>
              <Link to="https://www.offerrushdeals.com/privacy-policy-2">Privacy Policy</Link>
              <Link to="https://www.offerrushdeals.com/terms-and-conditions">Terms and Conditions</Link>
              {/*<Link to="">Blog</Link>*/}
            </div>
           
            <div>
              <h3>Connect</h3>
              <Link to="https://www.linkedin.com/company/offerrush/">Linkedin</Link>
              <Link to="https://youtube.com/@itechsearch?si=eSEbgPnN4zk-m_cF">Youtube</Link>
              <Link to="https://www.instagram.com/offerrush?igsh=NDZ4M2twNHY5Z3A5">Instagram</Link>
              <Link to="https://t.me/itechsearchtg">Telegram</Link>
            </div>

            <div>
              <h3>Resources</h3>
              <Link to="https://t.me/itechsearchtg">Join on Telegram</Link>
              <Link to="https://play.google.com/store/apps/details?id=com.offerrush.deals&hl=en_IN">
                Download App
              </Link>
            </div>
          </div>
        </div>
        <p>© 2020-2024, Offerrush All Rights Reserved</p>
      </footer>

      <div className="footer-bottom">
        <b>
          <h2 className="footer-hirespoof">Offerrush</h2>
        </b>
      </div>
    </div>
  );
};

export default Header;