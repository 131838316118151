// AboutUs.js

import React from 'react';
import './AboutUs.css'; // Import your CSS file

const AboutUs = () => {
  return (
    <div>
      <h2>About Us</h2>
      <p>
        Welcome To  Offerrush. Offerrush is a Professional E-Commerce Platform.
        Here we will Provide you only interesting content, which you will like very much.
        We’re dedicated to providing you the best of E-Commerce, with a focus on
        dependability and Online Shopping Deals. We’re working to turn our passion for
        E-Commerce into a booming online website. We hope you enjoy our E-Commerce as
        much as we enjoy offering them to you.
      </p>
      <p>
        I will keep posting more important posts on my Website for all of you.
        Please give your support and love.
      </p>
      <p>Thanks For Visiting Our Site</p>
    </div>
  );
};

export default AboutUs;
